.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
}

img {
    border-radius: 50%;
    height: 12.25rem;
    width: 12rem;
}

h3{
    font-style: italic;
}

.header h2{
    font-size: 1.75rem;
}

a {
    text-decoration: none;
    color: rgb(3, 189, 246);
}

a:hover {
    color: rgb(231, 3, 3);
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .header h2 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 535px) {
    .header h2 {
        font-size: 1.25rem;
    }
    .header img {
        height: 10.25rem;
        width: 10rem;
    }
}

@media only screen and (max-width: 425px) {
    .header h2 {
        font-size: 1rem;
    }
}