.footer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: black;
    color: aliceblue;
    width: 100%;
    min-height: 10rem;
    margin-top: 10%;
}

@media only screen and (max-width: 535px) {
    .footer {
        font-size: 0.75rem;
        min-height: 7.5rem;
    }

}