/* Home */
#warning {
    color: rgb(231, 3, 3);
    margin-top: 1rem;
}

/* Blurb */
.blurb {
    /* background-color: rgb(230, 229, 225); */
    background-image: linear-gradient(to bottom, rgb(230, 229, 225) 0 3.5rem, rgb(252, 251, 248) 3.5rem 100%);
    border-radius: 1rem;
    margin: 2rem auto;
    padding: 0.5rem 1rem;
}

.blurb h1 {
    width: fit-content;
    background-image: linear-gradient(to right, rgb(3, 189, 246) 0 35%, yellow 65% 100%);
    background-clip: text;
    color: transparent;
}

.blurb p {
    margin-bottom: 0.5rem;
}

/* GetConnected */
.getconnected {
    /* background-color: rgb(230, 229, 225); */
    background-image: linear-gradient(to bottom, rgb(230, 229, 225) 0 2.75rem, rgb(252, 251, 248) 2.75rem 100%);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
}

h3.h3 {
    width: fit-content;
    background-image: linear-gradient(to right, rgb(3, 189, 246) 0 35%, yellow 65% 100%);
    background-clip: text;
    color: transparent;
}

.question1 h2 {
    text-align: center;
}

.question1 p {
    display: inline;
    text-align: right;
}

.connect-text {
    text-align: center;
}

.timeframeTextArea {
    margin-left: 2rem;
}

/* Buyer */
#buyerButton {
    font-size: 0.9rem;
    background-color: rgb(3, 189, 246);
    border: 1px solid rgb(3, 189, 246);
    transition: all 0.4s ease;
    transform: scale(1);
}

#buyerButton:hover {
    background-color: rgb(3, 189, 246);
    border: 1px solid rgb(3, 189, 246);
    transform: scale(1.1) perspective(1px);
}

#buyerButton:active {
    background-color: rgb(3, 189, 246);
    border: 1px solid rgb(3, 189, 246);
    transform: scale(0.9) perspective(1px);
}

@media only screen and (max-width: 600px) {
    .question1 h2 {
        font-size: 1.5rem;
    }
    .buyer h3.question2 {
        font-size: 1.3rem;
    }
    .buyer h5 {
        font-size: 1.1rem;
    }
    .seller h3.question2 {
        font-size: 1.3rem;
    }
    .seller h5 {
        font-size: 1.1rem;
    }
}

@media only screen and (max-width: 550px) {
    .blurb p:first-child {
        margin-top: 1rem;
    }
    .blurb p {
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 535px) {
    .header h2 {
        font-size: 1.25rem;
    }
    .header img {
        height: 10.25rem;
        width: 10rem;
    }
}

@media only screen and (max-width: 425px) {
    .header h2 {
        font-size: 1rem;
    }
}
