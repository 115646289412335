.prevtrans {
    /* background-color: rgb(230, 229, 225); */
    background-image: linear-gradient(to bottom, rgb(230, 229, 225) 0 3.5rem, rgb(252, 251, 248) 3.5rem 100%);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    margin: 2rem auto;
}

.prevtrans h2 {
    width: fit-content;
    background-image: linear-gradient(to right, rgb(3, 189, 246) 0 35%, yellow 65% 100%);
    background-clip: text;
    color: transparent;
    margin-bottom: 2rem;
}

.prevtrans div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

h2 {
    font-size: 1.75rem;
    font-style: italic;
}

.btn {
    transform: scale(1);
    transition: transform 0.3s ease-in-out
}

.btn:hover {
    cursor: pointer;
    transform: scale(1.02) perspective(1px);
}

.btn:active {
    border: none;
}

.card-body {
    flex-direction: column;
    align-items: center;
}

